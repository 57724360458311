<template>
  <div class="container">
    <div class="header">
      <p>AI 백선생</p>
      <v-icon @click="copyLink">mdi-export-variant</v-icon>
    </div>
    <div class="contents">
      <h2 class="title">연락처 등록 완료</h2>
      <p class="description">매주 분석을 카카오톡으로 보내드려요.</p>
      <img
        :src="require('@/assets/img/completeImage.png')"
        style="width: 100%"
      />
    </div>
    <p class="sub">
      저렴한 가격으로 블로그 마케팅 무제한! <br />슈퍼멤버스에서 인플루언서를
      만나보세요.
    </p>
    <div class="footer">
      <Button label="블로그 마케팅으로 매출 올리러가기" @click="moveMain" />
    </div>
  </div>
</template>

<script>
import Button from '../../components/ai/Button.vue';

export default {
  components: {
    Button,
  },

  methods: {
    copyLink() {
      navigator.clipboard.writeText('https://supermembers.co.kr/aibaek/');
    },
    moveMain() {
      this.$router.push({ path: `/` });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ebebeb;
}

.header {
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;

  p {
    color: #333;
    text-align: center;
    /* subline-bold */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
  }

  button {
    color: #333;
    position: absolute;
    right: 16px;
  }
}

.footer {
  height: 85px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 16px;
  border-top: 1px solid rgba(204, 204, 204, 1);
}

.contents {
  flex: 1;
  padding: 0px 16px;

  .title {
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    margin-top: 32px;
    margin-bottom: 4px;
  }

  .description {
    color: #333;
    /* body-medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-bottom: 16px;
  }
}

.sub {
  color: #999;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  text-align: center;
  margin-bottom: 12px;
}
</style>
